import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { http, uaHelper } from "@/utils"
import TopBar from "@/components/TopBar"
import { NewsDetailList } from "@/components/DetailList"

function ReportList () {
  const topPic = "https://anchor-main-oss.anchorsports.cn/nba/slh5/images/topban-1.jpg"

  const [newsList, setNewsList] = useState([])

  const [params] = useSearchParams()
  useEffect(() => {
    document.title = '选秀报告'

    //神策小程序替换H5匿名ID
    let distinctID = params.get('distinctID')

    //神策
    var sensors = window['sensorsDataAnalytic201505']

    //替换匿名ID
    if (distinctID !== null) {
      sensors.identify(distinctID, true)
    }

    //识别多端
    let DTC = uaHelper()
    if (DTC === 'wechat_mini') {
      sensors.registerPage({
        all_platform_type: 'wechat_mini'
      })
    } else if (DTC === 'alipay_mini') {
      sensors.registerPage({
        all_platform_type: 'alipay_mini'
      })
    } else if (DTC === 'nbaapp') {
      sensors.registerPage({
        all_platform_type: 'nbaapp'
      })
    } else {
      sensors.registerPage({
        all_platform_type: 'campaign'
      })
    }

    sensors.quick('autoTrack')
    http.get('/api/nba/getReport')
      .then((res) => {
        if (res.data.reportData !== undefined && res.data.reportData !== null) {
          setNewsList(res.data.reportData)
        }
      })
  }, [params])
  return (
    <>
      <TopBar pic={topPic} />
      <NewsDetailList newsList={newsList} />
    </>
  )
}

export default ReportList