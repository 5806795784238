import { Link } from 'react-router-dom'

function VideoList ({ videoList }) {

  return (
    <div className="main-vids">
      <div className="title">
        精彩视频
        <Link to="videos_list"><img src="https://anchor-main-oss.anchorsports.cn/nba/slh5/images/btn-more.png" className="more" alt='更多' /></Link>
      </div>
      <ul>
        {videoList.map(item => (
          <li key={item.id}>
            <a href={item.href}>
              <div className="img">
                <img src={item.pic} className="bg" alt={item.title} />
                <img src="https://anchor-main-oss.anchorsports.cn/nba/slh5/images/btn-play.png" className="icon" alt='' />
              </div>
              <span className="txt" dangerouslySetInnerHTML={{ __html: item.title }}></span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default VideoList