import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'


function SwiperBar ({ sliderList }) {
  const initialSlide = 1
  const spaceBetween = 0
  return (
    <>
      <Swiper
        modules={[Pagination, Autoplay]}
        loop={true}
        autoplay={true}
        initialSlide={initialSlide}
        spaceBetween={spaceBetween}
        // onSlideChange={(e) => console.log('slide change', e.realIndex)}
        // onSwiper={(swiper) => console.log(swiper)}
        pagination
        className='mySwiper'
      >
        {sliderList.map(item => (
          <SwiperSlide key={item.id}>
            <a href={item.href}>
              <img src={item.pic} alt={item.title} />
              <span className="title" dangerouslySetInnerHTML={{ __html: item.title }}></span>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

export default SwiperBar