import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './App.css'

import Home from './pages/Home'
import NewsList from './pages/NewsList'
import VideosList from './pages/VideoList'
import PlayerList from './pages/PlayerList'
import ReportList from './pages/ReportList'


function App () {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path='/' element={<Home />} render={() => { document.title = '首页' }}></Route>
          <Route path='/news_list' element={<NewsList />}></Route>
          <Route path='/videos_list' element={<VideosList />}></Route>
          <Route path='/player_list' element={<PlayerList />}></Route>
          <Route path='/report_list' element={<ReportList />}></Route>
        </Routes>
      </div>
    </BrowserRouter>
  )
}


export default App