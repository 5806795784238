import { Link } from 'react-router-dom'

function LatestNews ({ newsList }) {
  return (
    <div className="main-news">
      <div className="title">
        夏联精彩
      </div>
      <ul>
        {newsList.map(item => (
          <li key={item.id}>
            <a href={item.href}>
              <div className="img"><img src={item.pic} alt={item.title} /></div>
              <span className="txt" dangerouslySetInnerHTML={{ __html: item.title }}></span>
            </a>
          </li>
        ))}
      </ul>

      <Link to="/news_list">
        <div className="more"><img src="https://anchor-main-oss.anchorsports.cn/nba/slh5/images/btn-1.png" alt='更多精彩内容' /></div>
      </Link>
    </div>
  )
}

export default LatestNews