import { useEffect, useState } from 'react'
import { http } from '@/utils'
import MyLink from '../MyLink'

function Nav () {
  function getPlayerLink () {
    return http.get('/api/nba/getLink?code=playerlink')
  }
  function getSummerLink () {
    return http.get('/api/nba/getLink?code=summerlink')
  }
  function getReportLink () {
    return http.get('/api/nba/getLink?code=reportlink')
  }
  // function getDraftLink () {
  //   return http.get('/api/nba/getLink?code=draftlink')
  // }

  const [playerLink, setPlayerLink] = useState('')
  const [summerLink, setSummerLink] = useState('')
  const [reportLink, setReportLink] = useState('')
  // const [draftLink, setDraftLink] = useState('')
  useEffect(() => {
    getPlayerLink().then((res) => {
      setPlayerLink(res.href)
    })
    getSummerLink().then((res) => {
      setSummerLink(res.href)
    })
    getReportLink().then((res) => {
      setReportLink(res.href)
    })
    // getDraftLink().then((res) => {
    //   setDraftLink(res.href)
    // })
  }, [])
  return (
    <div className="nav">
      <MyLink href={playerLink} ></MyLink>
      <MyLink href={summerLink} > </MyLink>
      <MyLink href={reportLink} > </MyLink>
      {/* <MyLink href={draftLink} > </MyLink> */}
    </div>
  )
}
export default Nav