import { Link } from 'react-router-dom'

function MyLink ({ href }) {
  if (href.toLowerCase().startsWith('http://')
    || href.toLowerCase().startsWith('https://')) {
    return (
      <a href={href}> </a>
    )
  } else {
    return (
      <Link to={href}></Link>
    )
  }
}

export default MyLink