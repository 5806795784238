

function TimeLine ({ timeList }) {

  return (
    <div className="main-tline">
      <div className="title">
        夏联之旅共度过
      </div>
      <ul className="ul-tl">

        {timeList.map(item => (
          <li key={item.id}>
            <a href={item.href}>
              <div className="line"></div>
              <div className="left">
                <span>{item.timeDiff}</span>{item.eventTime1} {item.eventTime2}
                <i className="dot"></i>
              </div>
              <div className="main-box">
                <span className="tit">{item.title}</span>
                <span className="sub-tit">
                  {item.subTitle}
                </span>
              </div>
            </a>
          </li>
        ))}

      </ul>
    </div>
  )
}

export default TimeLine