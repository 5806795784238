

function NewsDetailList ({ newsList }) {

  return (
    <ul className="detail-list">
      {newsList.map(item => (
        <li key={item.id}>
          <a href={item.href}>
            <div className="img"><img src={item.pic} className="bg" alt={item.title} /></div>
            <span className="txt" dangerouslySetInnerHTML={{ __html: item.title }}></span>
            <span className="time">{item.time}</span>
          </a>
        </li>
      ))}

    </ul>
  )
}

function VideosDetailList ({ videoList }) {

  return (
    <ul className="detail-list">
      {videoList.map(item => (
        <li key={item.id}>
          <a href={item.href}>
            <div className="img">
              <img src={item.pic} className="bg" alt={item.title} />
              <img src="https://anchor-main-oss.anchorsports.cn/nba/slh5/images/btn-play.png" className="icon" alt='play' />
            </div>
            <span className="txt">{item.title}</span>
            <span className="time">{item.time}</span>
          </a>
        </li>
      ))}

    </ul>
  )
}

export { NewsDetailList, VideosDetailList }