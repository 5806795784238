import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { http, uaHelper } from "@/utils"

import TopBar from "@/components/TopBar"
import SwiperBar from "@/components/SwiperBar"
import Nav from "@/components/Nav"
import LatestNews from "@/components/LatestNews"
import VideoList from "@/components/VideoList"
import TimeLine from "@/components/TimeLine"
import Footer from "@/components/Footer"


function Home () {
  const topPic = "https://anchor-main-oss.anchorsports.cn/nba/slh5/images/topban.jpg"
  const [sliderList, setSliderList] = useState([])
  const [newsList, setNewsList] = useState([])
  const [videoList, setVideoList] = useState([])
  const [timeList, setTimeList] = useState([])

  const [params] = useSearchParams()
  useEffect(() => {
    document.title = '夏联精彩 尽收眼底'
    // toast(uaHelper())

    //神策小程序替换H5匿名ID
    let distinctID = params.get('distinctID')

    //神策
    var sensors = window['sensorsDataAnalytic201505']

    //替换匿名ID
    if (distinctID !== null) {
      sensors.identify(distinctID, true)
    }

    //识别多端
    let DTC = uaHelper()
    if (DTC === 'wechat_mini') {
      sensors.registerPage({
        all_platform_type: 'wechat_mini'
      })
    } else if (DTC === 'alipay_mini') {
      sensors.registerPage({
        all_platform_type: 'alipay_mini'
      })
    } else if (DTC === 'nbaapp') {
      sensors.registerPage({
        all_platform_type: 'nbaapp'
      })
    } else {
      sensors.registerPage({
        all_platform_type: 'campaign'
      })
    }

    sensors.quick('autoTrack')
    http.get('/api/nba/getHomeData')
      .then((res) => {
        if (res.data.sliderData !== undefined && res.data.sliderData !== null) {
          setSliderList(res.data.sliderData)
        }
        if (res.data.newsData !== undefined && res.data.newsData !== null) {
          setNewsList(res.data.newsData)
        }
        if (res.data.videosData !== undefined && res.data.videosData !== null) {
          setVideoList(res.data.videosData)
        }
        if (res.data.timelineArr !== undefined && res.data.timelineArr !== null) {
          setTimeList(res.data.timelineArr)
        }

        // setHomeData(res)
      })
  }, [params])
  return (
    <>
      <TopBar pic={topPic} />
      <SwiperBar sliderList={sliderList} />
      <Nav />
      <LatestNews newsList={newsList} />
      <VideoList videoList={videoList} />
      <TimeLine timeList={timeList} />
      <Footer />
      <ToastContainer />
    </>
  )
}

export default Home