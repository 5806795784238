export const uaHelper = () => {
  let ua = navigator.userAgent

  let wxmp = /miniProgram/gi.test(ua)
  let wx = /MicroMessenger/gi.test(ua)
  if (wx && wxmp) return 'wechat_mini'
  if (wx) return 'wechat'
  let alipay = /AlipayClient/gi.test(ua)
  if (alipay) return 'alipay_mini'
  let nba = /nba/gi.test(ua)
  if (nba) return 'nbaapp'

  return ""
}